var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';
import { useMemo, useState } from 'react';
import Select from '@shared/ui/inputs/Select';
import WalletGroupHeader from './WalletGroupHeader';
import { WalletValue } from './shared';
import { defineWalletsDict } from './utils';
const MenuItem = styled(MuiMenuItem)(() => ({
    justifyContent: 'space-between',
}));
const SelectStyled = styled(Select)(props => ({
    '& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: props.theme.spacing_sizes.s * 7,
    },
    '& .MuiList-root': {
        padding: 0,
    },
}));
const WalletSelect = (_a) => {
    var { items, onChange = undefined, value, renderEmptyValue = undefined } = _a, props = __rest(_a, ["items", "onChange", "value", "renderEmptyValue"]);
    const [isOpen, setIsOpen] = useState(false);
    const walletsDict = useMemo(() => defineWalletsDict(items), [items]);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleChange = (itemId) => (ev) => {
        if (onChange) {
            onChange(ev, itemId);
        }
        handleClose();
    };
    const walletOptions = useMemo(() => {
        if (items.length) {
            const options = [];
            items.forEach((item) => {
                var _a;
                if (item.label) {
                    options.push({
                        label: item.label,
                        key: `${item.key}-header`,
                        isHeader: true,
                    });
                }
                if ((_a = item.items) === null || _a === void 0 ? void 0 : _a.length) {
                    item.items.forEach((subItem) => {
                        options.push({
                            key: subItem.id,
                            item: subItem,
                            isHeader: false,
                            selected: subItem.id === value,
                            onClick: handleChange(subItem.id),
                        });
                    });
                }
            });
            return options;
        }
        return [];
    }, [items, value]);
    const renderValue = (valueToRender) => {
        if (valueToRender.length === 0)
            return renderEmptyValue || null;
        return _jsx(WalletValue, { item: walletsDict[valueToRender] });
    };
    return (_jsx(SelectStyled, Object.assign({}, props, { MenuProps: {
            tabIndex: 0,
        }, defaultValue: value, renderValue: renderValue, displayEmpty: true, onOpen: handleOpen, onClose: handleClose, value: value, open: isOpen, children: walletOptions.map((option) => {
            if (option.isHeader) {
                const headerOption = option;
                return (_jsx(WalletGroupHeader, { label: headerOption.label }, headerOption.key));
            }
            const itemOption = option;
            return (_jsx(MenuItem, { "aria-label": `option ${itemOption.item.walletName}`, value: itemOption.item.id, selected: Boolean(itemOption.selected), onClick: itemOption.onClick, children: _jsx(WalletValue, { item: itemOption.item }) }, itemOption.key));
        }) })));
};
export default WalletSelect;
