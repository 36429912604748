import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodyMediumSemiBold } from '@components/styled/Typography';
const HeaderText = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.secondary,
    padding: `${props.theme.spacing_sizes.xs * 1.25}px 15px`,
    pointerEvents: 'none',
    cursor: 'default',
}));
const WalletGroupHeader = ({ label }) => _jsx(HeaderText, { children: label });
export default WalletGroupHeader;
