var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import MuiTextField from '@mui/material/TextField';
import { forwardRef, useState, } from 'react';
import InputLabel from '@shared/ui/inputs/InputLabel';
import { TEXTAREA_SIZES } from './config';
const TextFieldComponent = styled(MuiTextField, {
    shouldForwardProp: (propName) => propName !== 'hasCounter' && propName !== 'hasLabel',
})(props => {
    var _a, _b, _c, _d;
    return ({
        '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: props.theme.customColors.input.backgroundDisabled,
        },
        '& .MuiInputBase-root': {
            fontSize: (_a = TEXTAREA_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.valueSize,
            padding: (_b = TEXTAREA_SIZES[props.size]) === null || _b === void 0 ? void 0 : _b.inputPadding,
            lineHeight: (_c = TEXTAREA_SIZES[props.size]) === null || _c === void 0 ? void 0 : _c.lineHeight,
            color: props.theme.palette.text.primary,
            cursor: 'default',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
            height: 'auto',
        },
        '& .MuiInputBase-input': {
            position: 'relative',
            paddingRight: 2,
            cursor: 'auto',
        },
        '& .MuiInputBase-input.Mui-disabled': {
            color: props.theme.palette.text.primary,
            WebkitTextFillColor: 'inherit',
        },
        '& label.MuiInputLabel-root': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            color: props.theme.palette.text.primary,
            marginBottom: (props === null || props === void 0 ? void 0 : props.hasLabel) ?
                (_d = TEXTAREA_SIZES[props.size]) === null || _d === void 0 ? void 0 : _d.labelMarginBottom :
                0,
            fontWeight: 500,
            display: 'flex',
            justifyContent: 'space-between',
        },
        legend: {
            visibility: 'hidden',
            width: 0,
        },
        '& .MuiInputBase-input::-webkit-scrollbar': {
            borderRadius: 4,
            width: 6,
        },
        '& .MuiInputBase-input::-webkit-scrollbar-thumb': {
            background: '#C1C8CD',
            width: 4,
            borderRadius: 4,
        },
    });
});
const TextareaComponent = (_a, ref) => {
    var { size = 'medium', notificationStatus = undefined, value = undefined, label = undefined, onBlur = undefined, maxCount = undefined, minRows = 5, required = undefined } = _a, props = __rest(_a, ["size", "notificationStatus", "value", "label", "onBlur", "maxCount", "minRows", "required"]);
    const [focused, setIsFocused] = useState(false);
    const handleBlur = (ev) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(ev);
        }
    };
    const handleFocus = () => {
        setIsFocused(true);
    };
    return (_jsx(TextFieldComponent, Object.assign({}, props, { label: (_jsx(InputLabel, { count: value === null || value === void 0 ? void 0 : value.length, focused: focused, label: label, maxCount: maxCount, size: size, required: required })), hasLabel: typeof label !== 'undefined', value: value, variant: 'outlined', multiline: true, size: size, minRows: minRows, onBlur: handleBlur, onFocus: handleFocus, error: notificationStatus === 'error', ref: ref })));
};
const Textarea = forwardRef(TextareaComponent);
export default Textarea;
