const defineWalletsDict = (items) => {
    const result = {};
    items.forEach((elem) => {
        if (elem.items) {
            elem.items.forEach((subItem) => {
                result[subItem.id] = subItem;
            });
        }
    });
    return result;
};
export { 
// eslint-disable-next-line import/prefer-default-export
defineWalletsDict, };
