// eslint-disable-next-line import/prefer-default-export
export const TEXTAREA_SIZES = {
    medium: {
        labelSize: '16px',
        labelMarginBottom: '12px',
        lineHeight: '24px',
        valueSize: '16px',
        inputPadding: '12px 6px 12px 16px',
    },
    small: {
        labelSize: '14px',
        labelMarginBottom: '10px',
        lineHeight: '20px',
        valueSize: '14px',
        inputPadding: '10px 6px 10px 12px',
    },
};
